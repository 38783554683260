.DateLine {
    margin: 30px 0;
    text-align: right;
}

@media only screen and (max-width: 1019px) {
    .DateLine {
        max-width: 95%;
        margin-right: auto;
        margin-left: auto;
    }
}

@media only screen and (max-width: 959px) {
    .DateLine {
        padding: 0;
        width: 80%;
        text-align: center;
    }
}
