.LeadArticles {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    position: relative;
    margin-top: 140px;
    margin-bottom: 100px;
}

.LeadArticles:after {
    content: "Schlagzeilen";
    position: absolute;
    top: -43px;
    left: 0;
    background-color: #FFFFFF;
    margin-left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
    font-size: 22px;
    font-weight: bold;
}

.LeadArticles:before {
    border-top: 1px solid black;
    position: absolute;
    top: -30px;
    width: 90%;
    content: "";
}

@media only screen and (max-width: 567px) {
    .LeadArticles:before {
        width: 90%;
    }
}

@media only screen and (max-width: 567px) {
    .LeadArticles {
        display: none;
    }
}
