.Entry {
    border: 1px solid #CCCCCC;
    width: 80%;
    max-width: 700px;
    min-width: 260px;
    border-radius: 3px;
    box-shadow: 0 2px 10px -6px #333333;
    margin: 60px;
    padding: 20px;
    text-align: left;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    line-height: 1.5em;
    position: relative;
}

.Entry .PreviewImage {
    margin: 0;
    margin-bottom: 10px;
}

.Entry .PreviewImage img {
    width: 100%;
}

.Entry.TopArticle {
    max-width: 100%;
    width: 100%;
    margin-top: 80px;
}

.Entry.TopArticle:before {
    content: "Top Story";
    position: absolute;
    top: -40px;
    left: 0;
    font-size: 30px;
    color: #FFFFFF;
    background-color: #000000;
    display: inline-block;
    padding: 8px 14px;
    border-radius: 5px 5px 0 0;
}

@media only screen and (max-width: 1019px) {
    .Entry.TopArticle {
        max-width: 95%;
    }
}

@media only screen and (max-width: 567px) {
    .Entry {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .Entry.TopArticle {
        max-width: 80%;
        margin-top: 60px;
        margin-bottom: 40px;
    }

    .Entry.TopArticle:before {
        top: -40px;
        font-size: 20px;
    }
}

.Entry.LeadArticle {
    max-width: 200px;
    margin: 10px;
    min-width: auto;
}

.Entry .Category {
    font-size: 12px;
}

.Entry .Category span {
    font-weight: bold;
}

.Entry.LeadArticle h2 {
    font-size: 16px;
}
