.Reloader {
    text-align: right;
    margin-right: auto;
    margin-left: auto;
    margin-top: -15px;
    margin-bottom: 75px;
}

@media only screen and (max-width: 1019px) {
    .Reloader {
        max-width: 95%;
    }
}

@media only screen and (max-width: 959px) {
    .Reloader {
        text-align: center;
    }
}

.Reloader button {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #FFAEAE;
    border: 2px solid #FF6969;
    outline-style: none;
    cursor: pointer;
}

.active button {
    background-color: #B3FF9E;
    border: 2px solid #54AE45;
}
