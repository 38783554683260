.Details {
    margin-right: auto;
    margin-left: auto;
}

.Entry p, .Entry strong {
    margin-bottom: 30px;
    display: block;
}

.Entry hr + p {
    font-size: 12px;
}

.Entry img:not(.imageRight) + p:after,
.Details:after {
    clear: both;
    content: "";
    display: table;
}

@media only screen and (max-width: 1019px) {
    .Details {
        width: 90%;
    }
}

.Entry {
    text-align: left;
    line-height: 1.5em;
}

.Entry h1 {
    margin: 50px 0;
    line-height: 1.5em;
}

.Entry img {
    width: 50%;
    height: auto;
    max-width: 400px;
    margin: 0;
    float: left;
    margin-right: 25px;
}

.Entry .alignCenter {
    text-align: center;
}

.Entry .imageRight {
    float: right;
    margin-left: 25px;
    margin-bottom: 25px;
}

.Entry .imageBlock {
    display: block;
    margin-left: 25px;
    margin-bottom: 25px;
    max-width: 400px;
    float: right;
}

.Entry .imageBlock .imageRight {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}

@media only screen and (max-width: 567px) {
    .Entry h1 {
        margin: 30px 0;
        font-size: 24px;
    }

    .Entry img,
    .Entry .imageBlock {
        width: 100%;
        max-width: 100%;
        float: none;
        margin-right: 0;
        margin-bottom: 25px;
    }

    .Entry .imageBlock {
        margin-left: 0;
    }
}
